import * as React from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import type { HeadFC, PageProps } from 'gatsby';
import Layout from '../components/layout/layout';
import '../styles/reset.scss';
import '../styles/PC/common.scss';
import '../styles/SP/common.scss';
import '../styles/PC/index.scss';
import '../styles/SP/index.scss';
import SvgImage from '../components/image/svg-image';
import NormalImage from '../components/image/normal-image';
import scrollTo from 'gatsby-plugin-smoothscroll';
import '@fontsource/zen-maru-gothic';
import videoSrc from '../video/movie01.mp4';
import videoPoster from '../video/poster.jpg';
import { gsap } from 'gsap';
import { Formik, Form, Field } from 'formik';
import ContactForm from '../components/form/contact-form';
import Information from '../components/Information';

const IndexPage: React.FC<PageProps> = () => {
  const [play, setPlay] = React.useState(false);
  const videoRef = React.useRef<HTMLMediaElement>(null);

  const [buttonSelect, setButtonSelect] = React.useState<string>('use');

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation();
  }, []);
  React.useEffect(() => {}, []);

  const moviePlay = () => {
    setPlay(true);
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.setAttribute('controls', 'controls');
    }
  };

  const setAnimation = () => {
    gsap.utils.toArray('.anime-up').forEach(function (target) {
      gsap.fromTo(
        target,
        {
          y: 200,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: target,
            start: 'top bottom', //開始時のトリガー条件
            end: 'middle top', //終了時のトリガー条件
            onEnter: () => {}, //スクロールイン時
            onEnterBack: () => {}, //スクロールバック時
            markers: false, // マーカー表示
          },
        }
      );
    });

    gsap.utils.toArray('.anime-in').forEach(function (target) {
      gsap.fromTo(
        target,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: target,
            start: 'top bottom', //開始時のトリガー条件
            end: 'middle top', //終了時のトリガー条件
            onEnter: () => {}, //スクロールイン時
            onEnterBack: () => {}, //スクロールバック時
            markers: true, // マーカー表示
          },
        }
      );
    });

    gsap.utils.toArray('.anime-left').forEach(function (target) {
      gsap.fromTo(
        target,
        {
          x: -300,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: target,
            start: 'top bottom', //開始時のトリガー条件
            end: 'middle top', //終了時のトリガー条件
            onEnter: () => {}, //スクロールイン時
            onEnterBack: () => {}, //スクロールバック時
            markers: false, // マーカー表示
          },
        }
      );
    });

    gsap.utils.toArray('.anime-right').forEach(function (target) {
      gsap.fromTo(
        target,
        {
          x: 300,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: target,
            start: 'top bottom', //開始時のトリガー条件
            end: 'middle top', //終了時のトリガー条件
            onEnter: () => {}, //スクロールイン時
            onEnterBack: () => {}, //スクロールバック時
            markers: false, // マーカー表示
          },
        }
      );
    });
  };

  return (
    <Layout>
      <main>
        <article id="main-visual">
          <div className="main-inner">
            <div id="main-text">
              <p className="text-zen-maru sub-text anime-up sp-none">
                現役の消防団員がつくった消防団のためのアプリ
              </p>
              <p className="sub-text-img anime-up pc-none">
                <SvgImage
                  filename="sp_txt_main_sub"
                  alt="現役の消防団員がつくった消防団のためのアプリ"
                />
              </p>
              <div className="text-zen-maru main-text anime-up">
                <span className="text-short text-zen-maru">消防団員の</span>
                <div className="bgc-y text-zen-maru">
                  <span className=" text-zen-maru">作業負荷</span>の
                  <span className="text-zen-maru">軽減</span>
                </div>
                と<br />
                <div className="bgc-y text-zen-maru">
                  <span className="text-zen-maru">出動実績</span>の
                  <span className="text-zen-maru">記録</span>
                </div>
                が<span className=" text-zen-maru">実現</span>
                <span className="text-zen-maru text-attention">!!</span>
              </div>
              <div className="main-text-img anime-up">
                <SvgImage filename="title" alt="タイトル" />
              </div>
            </div>
            <div id="main-title" className="anime-up">
              <SvgImage filename="main-title" alt="コミュたす" />
            </div>
            <div id="main-use">
              <div className="main-use-body">
                <div className="img-main-use01 anime-right">
                  <NormalImage filename="main_use01" alt="利用画面" />
                </div>
                <div className="img-main-use02 anime-right">
                  <NormalImage filename="main_use02" alt="消防車" />
                </div>
                <div className="img-main-use03 anime-right">
                  <SvgImage filename="main_use03" alt="操作イラスト" />
                </div>
              </div>
            </div>
            <div id="main-buttons">
              <div>
                <button
                  className="doc-button text-zen-maru"
                  onClick={() => scrollTo('#commutas-form')}
                >
                  資料で詳しく見る
                </button>
              </div>
              <div>
                <button
                  className="inquiry-button text-zen-maru"
                  onClick={() => scrollTo('#commutas-form')}
                >
                  お問い合わせ
                </button>
              </div>
            </div>
            <div className="line-memo">
              ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
            </div>
            <div id="main-line-msg" className="anime-up">
              <SvgImage filename="easy-use" alt="簡単操作" />
            </div>
          </div>
        </article>

        <Information />

        <article id="commutas">
          <div>
            <div className="title-flex anime-up sp-none">
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
              <h2 className="text-zen-maru">コミュたすとは？</h2>
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
            </div>
            <p className="title-supplement anime-up sp-none">commutas</p>
            <h2 className="title-image-wrapper anime-up pc-none">
              <SvgImage filename="ttl_sp_commutas" alt="コミュたすとは？" />
            </h2>

            <p className="text-zen-maru detail-text anime-up">
              <SvgImage filename="text_commutas" alt="コミュたすの概要" />
            </p>

            <div className="image-list anime-up">
              <NormalImage filename="img_commutas_1" alt="イメージ１" />
              <NormalImage filename="img_commutas_2" alt="イメージ２" />
              <NormalImage filename="img_commutas_3" alt="イメージ３" />
              <NormalImage filename="img_commutas_4" alt="イメージ４" />
            </div>

            <div className="commutas-movies">
              <h3 className="text-zen-maru anime-up">
                <SvgImage filename="txt_movie" alt="コミュたす" />
              </h3>
              <p className="sub-title text-zen-maru anime-up">解説動画</p>
              <div className="commutas-movie anime-up">
                <video
                  id="video"
                  ref={videoRef}
                  // controls
                  controlsList="nodownload"
                  poster={videoPoster}
                  // loop
                  // paused
                  width="100%"
                  height="100%"
                >
                  <source src={videoSrc} type="video/mp4" />
                  <p>ご使用のブラウザでは動画再生に対応していません</p>
                </video>
                <div
                  className={'movie-play-btn ' + (play ? 'play' : '')}
                  onClick={moviePlay}
                ></div>
                <div className={'movie-over ' + (play ? 'stop' : '')}></div>
              </div>
            </div>

            <div className="commutas-points">
              <div className="points-problem anime-up">
                <p className="text-zen-maru comment">
                  消防団の活動をもっと
                  <br />
                  スマートにしたい...
                </p>
              </div>
              <div className="human-image anime-up">
                <SvgImage filename="member" alt="消防団員" />
                <SvgImage filename="municipality" alt="自治体関係者" />
              </div>
              <div className="down-arrow anime-up">
                <SvgImage filename="down-arrow" alt="下矢印" />
              </div>
              <div className="answer">
                <SvgImage filename="text_answer" alt="" />
              </div>
              <div className="human-salute">
                <SvgImage filename="salute" alt="敬礼" />
              </div>

              <div className="points-list">
                <ul>
                  <li className="anime-up">
                    <div className="point-title">自治体関係者</div>
                    <p className="point-problem">
                      出動報酬の支給算定で使う
                      <br />
                      出動実績記録が残せていない
                    </p>
                    <p className="point-answer">
                      <SvgImage
                        filename="text_point_answer01"
                        alt="団員の活動記録を自動集計"
                      />
                    </p>
                    <div className="point-detail-warpper">
                      <p className="point-detail">
                        消防団員が登録した活動実績記録から期間や活動種別を選択して一括でデータを取得できます！
                      </p>
                      <div className="point-image">
                        <SvgImage filename="point01" alt="ポイント１" />
                      </div>
                    </div>
                    <div className="point-button">
                      <button onClick={() => scrollTo('#commentary04')}>
                        詳細を見る
                      </button>
                    </div>
                  </li>
                  <li className="anime-up">
                    <div className="point-title">自治体関係者</div>
                    <p className="point-problem">
                      出動不要範囲の災害情報が誤って
                      <br />
                      配信されてしまう
                    </p>
                    <p className="point-answer">
                      <SvgImage
                        filename="text_point_answer02"
                        alt="出動範囲の分団のみ指令を自動配信"
                      />
                    </p>
                    <div className="point-detail-warpper">
                      <p className="point-detail">
                        出動範囲の分団以外にも指令配信がされると誤出動となり、本来は不要な出動報酬が計上される事を防ぎます！
                      </p>
                      <div className="point-image">
                        <SvgImage filename="point02" alt="ポイント２" />
                      </div>
                    </div>
                    <div className="point-button">
                      <button onClick={() => scrollTo('#commentary01')}>
                        詳細を見る
                      </button>
                    </div>
                  </li>
                  <li className="anime-up">
                    <div className="point-title">自治体関係者</div>
                    <p className="point-problem">
                      どの地域で、どのような被災状況
                      <br />
                      か把握するのに苦戦している
                    </p>
                    <p className="point-answer">
                      <SvgImage
                        filename="text_point_answer03"
                        alt="被災状況が一覧でわかる"
                      />
                    </p>
                    <div className="point-detail-warpper">
                      <p className="point-detail">
                        災害の被災状況を簡単に共有でき、対応状況も可視化できます！消防団員が被災登録を行うので、信憑性の高い内容が報告されます！
                      </p>
                      <div className="point-image point03">
                        <SvgImage filename="point03" alt="ポイント３" />
                      </div>
                    </div>
                    <div className="point-button">
                      <button onClick={() => scrollTo('#commentary05')}>
                        詳細を見る
                      </button>
                    </div>
                  </li>

                  <li className="anime-up">
                    <div className="point-title member">消防団員</div>
                    <p className="point-problem member">
                      「どの災害に、何時間」出動した
                      <br />
                      のか記録や報告が面倒である
                    </p>
                    <p className="point-answer">
                      <SvgImage
                        filename="text_point_answer04"
                        alt="LINEからボタン一つで出動登録"
                      />
                    </p>
                    <div className="point-detail-warpper">
                      <p className="point-detail">
                        災害対応時の忙しい中でもLINE
                        <span className="line-comment"></span>
                        からボタン一つで出動記録を登録できます！リーダーでの出動報告も自動でできます！
                      </p>
                      <div className="point-image point04">
                        <SvgImage filename="point04" alt="ポイント４" />
                      </div>
                    </div>
                    <div className="point-button">
                      <button onClick={() => scrollTo('#commentary01')}>
                        詳細を見る
                      </button>
                    </div>
                  </li>
                  <li className="anime-up">
                    <div className="point-title member">消防団員</div>
                    <p className="point-problem member">
                      団員の出動状況や出動手段が共有
                      <br />
                      できず、連携がとれていない
                    </p>
                    <p className="point-answer">
                      <SvgImage
                        filename="text_point_answer05"
                        alt="リアルタイムで出動状況がわかる"
                      />
                    </p>
                    <div className="point-detail-warpper">
                      <p className="point-detail">
                        出動予定手段を共有でき、車両出動のタイミングや車両割り振りに活用できる！出動中団員/車両一覧も取得できます！
                      </p>
                      <div className="point-image point05">
                        <SvgImage filename="point05" alt="ポイント５" />
                      </div>
                    </div>
                    <div className="point-button">
                      <button onClick={() => scrollTo('#commentary02')}>
                        詳細を見る
                      </button>
                    </div>
                  </li>
                  <li className="anime-up">
                    <div className="point-title member">消防団員</div>
                    <p className="point-problem member">
                      災害発生地点が文字のみで配信され現地到着まで位置がわからない
                    </p>
                    <p className="point-answer">
                      <SvgImage
                        filename="text_point_answer06"
                        alt="火点や周辺水利がマップでわかる"
                      />
                    </p>
                    <div className="point-detail-warpper">
                      <p className="point-detail">
                        火点と周辺水利を同一マップに表示できホース使用予定本数や器具等を出動前の段階から把握し準備ができます！
                      </p>
                      <div className="point-image point06">
                        <SvgImage filename="point06" alt="ポイント６" />
                      </div>
                    </div>
                    <div className="point-button">
                      <button onClick={() => scrollTo('#commentary03')}>
                        詳細を見る
                      </button>
                    </div>
                  </li>
                </ul>
                <p className="line-memo">
                  ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
                </p>
              </div>
            </div>
          </div>
        </article>

        <article id="commutas-commentary">
          <div className="title-bg anime-up">
            <div className="title-flex sp-none">
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
              <h2 className="text-zen-maru">「コミュたす」機能紹介</h2>
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
            </div>
            <p className="title-supplement sp-none">feature info</p>
            <h2 className="title-image-wrapper pc-none">
              <SvgImage
                filename="ttl_sp_feature"
                alt="「コミュたす」機能紹介"
              />
            </h2>
          </div>
          <div className="commentary-wrapper commentary01-wrapper commentary-left anime-left">
            <div className="commentary-left-block"></div>
            <div className="commentary commentary01" id="commentary01">
              <div className="commentary-title-wrapper sp-none">
                <SvgImage filename="txt_featunre_01" alt="機能1" />
                <h4 className="text-zen-maru">
                  出動指令の通知機能
                  <br className="pc-none" />
                  【出火時】
                </h4>
              </div>
              <div className="commentary-title-wrapper pc-none">
                <SvgImage
                  filename="ttl_sp_feature_01"
                  alt="出動指令の通知機能【出火時】"
                />
              </div>
              <div className="commentary-image">
                <NormalImage filename="commentary01-01" alt="機能1-01" />
              </div>
              <p className="commentary-detail">
                火災発生時に消防団員のLINE<span className="line-comment"></span>
                に出動指令を通知します。
                <br className="sp-none" />
                出動対象の消防団員にのみ出動指令配信が可能です。
                <br />
                <br />
                団員は出動手段を選択し、団員同士が
                <br className="sp-none" />
                出動予定者と手段の把握ができます。また出動車両の状況を共有できます。
              </p>
              <div className="commentary-essence">
                <div className="point">
                  ここが
                  <br className="sp-none" />
                  便利
                </div>
                <ul>
                  <li>
                    <h5>自分の出動範囲の火災のみ、出動指令が通知されます。</h5>
                    <p>
                      土地勘のない団員や新入団員が、自身の出動範囲か
                      <br className="sp-none" />
                      わからないという困りごとを解決できます。
                    </p>
                  </li>
                  <li>
                    <h5>団員同士が出動予定者と手段の把握ができます。</h5>
                    <p>
                      誰がどの車両で出動しようとしているのかわからない
                      <br className="sp-none" />
                      という困りごとを解決できます。
                    </p>
                  </li>
                </ul>
              </div>
              <div className="commentary-point">
                <div className="commentary-point-title">
                  <SvgImage filename="point-icon" alt="ポイント" />
                  <h5 className="text-zen-maru">こんな使い方も！</h5>
                </div>
                <p>
                  LINE<span className="line-comment"></span>
                  グループチャット上で行われるため、チャット機能も
                  <br className="sp-none" />
                  通常通りつかえます。団員同士がコミュニケーションを取
                  <br className="sp-none" />
                  ることができます。
                </p>
                <div className="commentary-point-icon">
                  <NormalImage filename="commentary01-02" alt="機能1-02" />
                </div>
              </div>
              <p className="line-memo">
                ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
              </p>

              <div className="commentary-illustration01">
                <SvgImage filename="commentary01" alt="ポイント" />
              </div>
            </div>
            <div className="commentary-right-block"></div>
          </div>

          <div className="commentary-wrapper  commentary02-wrapper commentary-right anime-right">
            <div className="commentary-left-block"></div>
            <div className="commentary commentary02" id="commentary02">
              <div className="commentary-title-wrapper sp-none">
                <SvgImage filename="txt_featunre_02" alt="機能2" />
                <h4 className="text-zen-maru">
                  出動指令の通知機能
                  <br className="pc-none" />
                  【鎮火時】
                </h4>
              </div>
              <div className="commentary-title-wrapper pc-none">
                <SvgImage
                  filename="ttl_sp_feature_02"
                  alt="出動指令の通知機能【鎮火時】"
                />
              </div>
              <div className="commentary-image">
                <NormalImage filename="commentary02-01" alt="機能2-01" />
              </div>
              <p className="commentary-detail">
                火災鎮火時に消防団員のLINE<span className="line-comment"></span>
                に鎮火情報を通知します。
                <br />
                出動中団員一覧の確認とボタンから作業終了が登録できます。
              </p>
              <div className="commentary-essence">
                <div className="point">
                  ここが
                  <br className="sp-none" />
                  便利
                </div>
                <ul>
                  <li>
                    <h5>出動中の団員一覧が確認できます。</h5>
                    <p>現場で出動者を確認していた手間がなくなります。</p>
                  </li>
                  <li>
                    <h5>
                      活動終了時にボタンを押すことで、活動終了時刻の記録ができます。
                    </h5>
                    <p>
                      団員毎の活動時間を確認していた手間が
                      <br className="sp-none" />
                      なくなります。
                    </p>
                  </li>
                </ul>
              </div>
              <p className="line-memo">
                ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
              </p>

              <div className="commentary-illustration02">
                <SvgImage filename="commentary02" alt="ポイント" />
              </div>
            </div>
            <div className="commentary-right-block"></div>
          </div>

          <div className="commentary-wrapper commentary03-wrapper commentary-left anime-left">
            <div className="commentary-left-block"></div>
            <div className="commentary commentary03" id="commentary03">
              <div className="commentary-title-wrapper sp-none">
                <SvgImage filename="txt_featunre_03" alt="機能3" />
                <h4 className="text-zen-maru">
                  火点と周辺水利マップ
                  <br className="pc-none" />
                  の表示機能
                </h4>
              </div>
              <div className="commentary-title-wrapper pc-none">
                <SvgImage
                  filename="ttl_sp_feature_03"
                  alt="火点と周辺水利マップの表示"
                />
              </div>
              <div className="commentary-image">
                <NormalImage filename="commentary03-01" alt="機能3-01" />
              </div>
              <p className="commentary-detail">
                火点と周辺水利情報を同じマップ上に表示し、
                <br className="sp-none" />
                消防団員の消火活動を支援します。
                <br />
                各分団で管理しているGoogleマイマップ機能から水利データの取込も可能です。
                <br />
                水利以外にもAED設置場所などの登録も可能です。
              </p>
              <div className="commentary-essence">
                <div className="point">
                  ここが
                  <br className="sp-none" />
                  便利
                </div>
                <ul>
                  <li>
                    <h5>火点場所を地図に表示できます。</h5>
                    <p>
                      文字だけの災害発生メールでは火災場所が特定しづらいという困
                      <br className="sp-none" />
                      りごとが解決できます。
                    </p>
                  </li>
                  <li>
                    <h5>
                      現場到着前に火点近隣の水利の位置と種類が把握できます。
                    </h5>
                    <p>
                      火点に近い水利の場所と種別がわからないという困り
                      <br className="sp-none" />
                      ごとを解決できます。
                    </p>
                  </li>
                </ul>
              </div>
              <div className="commentary-point">
                <div className="commentary-point-title">
                  <SvgImage filename="point-icon" alt="ポイント" />
                  <h5 className="text-zen-maru">こんな使い方も！</h5>
                </div>
                <p>
                  ・消火栓と器具箱の位置関係を写真で表示できます。
                  <br />
                  ⇒消防団員が、積雪時に地下式消火栓のマンホールを探す時間を
                  <br className="sp-none" />
                  <span className="sp-none">　</span>
                  削減できます。また、応援出動した地域外の団員でも水利を見つけ
                  <br className="sp-none" />
                  <span className="sp-none">　</span>易くなります。
                  <span className="sp-none">（右図イメージ）</span>
                </p>
                <p className="text-wrap">
                  ・消火栓や器具箱の点検にも活用できます。
                  <br />
                  ⇒災害時だけでなく、通常の点検時にも位置の特定に活用することが
                  <br className="sp-none" />
                  <span className="sp-none">　</span>できます。
                </p>
                <div className="commentary-point-icon">
                  <NormalImage filename="commentary03-02" alt="機能3-02" />
                </div>
              </div>
              <div className="commentary-illustration03">
                <SvgImage filename="commentary03" alt="ポイント" />
              </div>
            </div>
            <div className="commentary-right-block"></div>
          </div>

          <div className="commentary-wrapper  commentary04-wrapper commentary-right anime-right">
            <div className="commentary-left-block"></div>
            <div className="commentary commentary04" id="commentary04">
              <div className="commentary-title-wrapper sp-none">
                <SvgImage filename="txt_featunre_04" alt="機能4" />
                <h4 className="text-zen-maru">
                  消防団員の活動記録登録
                  <br className="pc-none" />
                  /取得機能
                </h4>
              </div>
              <div className="commentary-title-wrapper pc-none">
                <SvgImage
                  filename="ttl_sp_feature_04"
                  alt="消防団員の活動記録登録/取得機能"
                />
              </div>
              <div className="commentary-image">
                <NormalImage filename="commentary04-01" alt="機能4-01" />
              </div>
              <p className="commentary-detail">
                消防団員の出動記録を管理できます。(消防団員毎/分団毎/災害毎 等)
                <br />
                出動報酬の支給計算や、活動実態の把握に活用できます。
              </p>
              <div className="commentary-essence">
                <div className="point">
                  ここが
                  <br className="sp-none" />
                  便利
                </div>
                <ul>
                  <li>
                    <h5>
                      登録されているすべての団員の活動記録をCSVファイルで一括出力できます。
                    </h5>
                    <p>
                      自治体の会計システムにデータを手入力していた手間が省けます。
                    </p>
                  </li>
                  <li>
                    <h5>
                      団員毎の出動履歴を記録・集計・出力することができます。
                    </h5>
                    <p>活動記録から活動時間の報告書が自動で作成できます。</p>
                  </li>
                  <li>
                    <h5>各自治体の既存フォーマットで出力できます。</h5>
                    <p>出動報酬の支給計算もしやすくなります。</p>
                  </li>
                </ul>
              </div>

              <div className="commentary-illustration04">
                <SvgImage filename="commentary04" alt="ポイント" />
              </div>
            </div>
            <div className="commentary-right-block"></div>
          </div>

          <div className="commentary-wrapper commentary05-wrapper commentary-left anime-left">
            <div className="commentary-left-block"></div>
            <div className="commentary commentary05" id="commentary05">
              <div className="commentary-title-wrapper sp-none">
                <SvgImage filename="txt_featunre_05" alt="機能5" />
                <h4 className="text-zen-maru">被災状況の共有機能</h4>
              </div>
              <div className="commentary-title-wrapper pc-none">
                <SvgImage
                  filename="ttl_sp_feature_05"
                  alt="被災状況の共有機能/取得"
                />
              </div>
              <div className="commentary-image">
                <NormalImage filename="commentary05-01" alt="機能5-01" />
              </div>
              <p className="commentary-detail">
                消防団員のスマートフォンから被災状況が登録できます。
                <br />
                登録した被災状況は、団員間や市町村の災害対策本部、通信指令室などに共
                <br className="sp-none" />
                有ができます。広域災害時には「いつ、どこで、何が、どのような状態であ
                <br className="sp-none" />
                るか」を共有する事ができます。
              </p>
              <div className="commentary-essence">
                <div className="point">
                  ここが
                  <br className="sp-none" />
                  便利
                </div>
                <ul>
                  <li>
                    <p>
                      ・登録された被災状況は一覧を時系列で確認したり、地図上に被災状況
                      <br className="sp-none" />
                      <span className="sp-none">　</span>
                      を表示したりすることで災害種別や地域毎の被災分布が見えるようになります。
                    </p>
                  </li>
                  <li>
                    <p>
                      ・専用機器が不要であり、消防団員は個人のスマートフォンから迅速に
                      <br className="sp-none" />
                      <span className="sp-none">　</span>
                      状況報告ができます。市町村のデジタル防災行政無線の終話を待たずに、
                      <br className="sp-none" />
                      <span className="sp-none">　</span>
                      被災状況を動画像を付加し共有できます。
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="commentary-right-block"></div>
          </div>
        </article>

        <article id="commutas-function">
          <div className="title-bg anime-up">
            <div className="title-flex sp-none">
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
              <h2 className="text-zen-maru">機能の比較</h2>
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
            </div>
            <p className="title-supplement sp-none">comparison</p>
            <h2 className="title-image-wrapper pc-none">
              <SvgImage filename="ttl_sp_comparison" alt="機能の比較" />
            </h2>
          </div>

          <table className="anime-up">
            <tbody>
              <tr>
                <th className="title-function">機　能</th>
                <th className="title-commutas">コミュたす</th>
                <th className="title-sys">Aシステム</th>
                <th className="title-sys">Bシステム</th>
              </tr>
              <tr>
                <td className="row-title">
                  出動指令
                  <br className="pc-none" />
                  トリガー
                </td>
                <td>
                  配信専用フォーム/
                  <br />
                  自治体災害発生メール
                </td>
                <td>配信専用フォーム</td>
                <td>
                  配信専用フォーム/
                  <br />
                  自治体災害発生メール
                </td>
              </tr>
              <tr>
                <td className="row-title">
                  出動指令
                  <br className="pc-none" />
                  受信方法
                </td>
                <td>
                  LINE<span className="line-comment"></span>アプリ
                </td>
                <td>専用アプリ</td>
                <td>専用アプリ/ブラウザ</td>
              </tr>
              <tr>
                <td className="row-title">
                  出動回答
                  <br className="pc-none" />機
                  <span className="pc-none">　</span>能
                </td>
                <td>出動可否と手段を回答</td>
                <td>出動可否のみを回答</td>
                <td>非対応</td>
              </tr>
              <tr>
                <td className="row-title">
                  団員間の
                  <br className="pc-none" />
                  情報共有
                </td>
                <td>チャット</td>
                <td>チャット</td>
                <td>掲示板</td>
              </tr>
              <tr>
                <td className="row-title">
                  火点の位置
                  <br className="pc-none" />
                  特定方法
                </td>
                <td>自　動</td>
                <td>手動(推測)</td>
                <td>手動(推測)</td>
              </tr>
              <tr>
                <td className="row-title">
                  火点の
                  <br className="pc-none" />
                  地図表示
                </td>
                <td>自　動</td>
                <td>自　動</td>
                <td>手　動</td>
              </tr>
              <tr>
                <td className="row-title">
                  水利位置の
                  <br className="pc-none" />表
                  <span className="pc-none">　</span>示
                </td>
                <td>自　動</td>
                <td>自　動</td>
                <td>手　動</td>
              </tr>
              <tr>
                <td className="row-title">
                  作業時間の
                  <br className="pc-none" />記
                  <span className="pc-none">　</span>録
                </td>
                <td>自　動</td>
                <td>自　動</td>
                <td>手　動</td>
              </tr>
              <tr>
                <td className="row-title">
                  現場滞在
                  <br className="pc-none" />
                  確認機能
                </td>
                <td>自動/手動（開発中）</td>
                <td>自動/手動</td>
                <td>手　動</td>
              </tr>

              <tr>
                <td className="row-title">
                  被災状況の
                  <br className="pc-none" />投
                  <span className="pc-none">　</span>稿
                </td>
                <td>可　能</td>
                <td>可　能</td>
                <td>可　能</td>
              </tr>
              <tr>
                <td className="row-title">
                  車両運用
                  <br className="pc-none" />
                  状況の共有
                </td>
                <td>可　能</td>
                <td>可　能</td>
                <td>非対応</td>
              </tr>
            </tbody>
          </table>
          <div className="line-memo">
            ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
          </div>
        </article>

        <article id="commutas-flow">
          <div className="title-bg anime-up">
            <div className="title-flex sp-none">
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
              <h2 className="text-zen-maru">導入フロー</h2>
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
            </div>
            <p className="title-supplement sp-none">flow</p>
            <h2 className="title-image-wrapper pc-none">
              <SvgImage filename="ttl_sp_flow" alt="導入フロー" />
            </h2>
          </div>

          <ul>
            <li className="anime-up">
              <div>
                <div className="step-icon">
                  <SvgImage filename="step_01" alt="STEP01" />
                </div>
                <div className="step-content">
                  <label className="text-zen-maru">
                    資料請求・お問い合わせ
                  </label>
                  <div>
                    ご希望の項目をクリックし、
                    <br className="pc-none" />
                    必要事項をご入力の上、送信願います。
                  </div>
                </div>
              </div>
            </li>
            <li className="anime-up">
              <div>
                <div className="step-icon">
                  <SvgImage filename="step_02" alt="STEP02" />
                </div>
                <div className="step-content">
                  <label className="text-zen-maru">ヒアリング・デモ</label>
                  <div>
                    お客様のご要望や、団員数の確認をさせていただきます。
                  </div>
                </div>
              </div>
            </li>
            <li className="anime-up">
              <div>
                <div className="step-icon">
                  <SvgImage filename="step_03" alt="STEP03" />
                </div>
                <div className="step-content">
                  <label className="text-zen-maru">お見積り</label>
                  <div>
                    お客様のご要望をもとにお見積りをさせていただきます。
                  </div>
                </div>
              </div>
            </li>
            <li className="anime-up">
              <div>
                <div className="step-icon">
                  <SvgImage filename="step_04" alt="STEP04" />
                </div>
                <div className="step-content">
                  <label className="text-zen-maru">ご契約</label>
                  <div className="text-2line">
                    サービス利用契約書・
                    <a
                      href="https://avasys.jp/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      プライバシーポリシー
                    </a>
                    をご確認いただき、
                    <br className="sp-none" />
                    問題がなければご契約締結させていただきます。
                  </div>
                </div>
              </div>
            </li>
            <li className="anime-up">
              <div>
                <div className="step-icon">
                  <SvgImage filename="step_05" alt="STEP05" />
                </div>
                <div className="step-content">
                  <label className="text-zen-maru">各種データの授受</label>
                  <div className="text-2line">
                    ご利用になられる方のお名前、所属分団などの団員個人情報や
                    <br className="sp-none" />
                    水利データ、災害地点と参集範囲の分団の情報などをご提供いただきます。
                  </div>
                </div>
              </div>
            </li>
            <li className="anime-up">
              <div>
                <div className="step-icon">
                  <SvgImage filename="step_06" alt="STEP06" />
                </div>
                <div className="step-content">
                  <label className="text-zen-maru">利用開始</label>
                  <div>災害発生時に団員の方に、メッセージを配信します。</div>
                </div>
              </div>
            </li>
          </ul>
        </article>

        <article id="commutas-qa" className="anime-up">
          <div className="title-bg">
            <div className="title-flex sp-none">
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
              <h2 className="text-zen-maru">よくある質問</h2>
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
            </div>
            <p className="title-supplement sp-none">FAQ</p>
            <h2 className="title-image-wrapper pc-none">
              <SvgImage filename="ttl_sp_faq" alt="よくある質問" />
            </h2>
          </div>
          <div className="qa-buttons">
            <button
              className={
                'use-button ' + (buttonSelect == 'use' ? ' active' : '')
              }
              onClick={() => setButtonSelect('use')}
            >
              使い方
            </button>
            <button
              className={
                'function-button ' +
                (buttonSelect == 'function' ? 'active' : '')
              }
              onClick={() => setButtonSelect('function')}
            >
              機　能
            </button>
            <button
              className={
                'contract-button ' +
                (buttonSelect == 'contract' ? 'active' : '')
              }
              onClick={() => setButtonSelect('contract')}
            >
              契　約
            </button>
            <button
              className={
                'system-button ' + (buttonSelect == 'system' ? 'active' : '')
              }
              onClick={() => setButtonSelect('system')}
            >
              システム
            </button>
          </div>
          <div
            className={
              'qa-lists use ' + (buttonSelect == 'use' ? 'active' : '')
            }
          >
            <ul>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    災害発生地点とメッセージ配信先の設定方法を教えてください。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>住所と配信先に関わる情報をご提供いただきます。</p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    試験配信や訓練などには対応できますか。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>
                    対応可能です。
                    <br />
                    配信用フォームに項目を入力することで配信できます。
                  </p>
                </div>
              </li>

              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    年度などの期間を指定して、全団員の活動履歴を一括で出力できますか。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>可能です。CSV形式で一括出力可能です。</p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    所属する消防団員の氏名や階級の編集はユーザー側でできますか。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>
                    可能です。
                    <br />
                    初回の消防団員登録のみ当社で実施致しますが、それ以降は自治体ご担当者様が編集可能です。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    地震や洪水、土砂災害などの被災状況報告方法を教えてください。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>
                    団員所有のスマートフォンの登録フォームから被災状況が登録できます。
                    <br />
                    被災個所の対応要否、対応未対応/完了なども管理することもできます。
                    <br />
                    本機能は災害対策本部などで状況を一覧で確認できます。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    使い方の説明会をお願いすることはできますか。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>可能です。</p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    水利位置登録や管理機能のサポートをお願いできますか。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>可能です。</p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    携帯電話の機種変更時にユーザーの再設定が必要か教えてほしい。
                  </p>
                  <button>使い方</button>
                </div>
                <div className="a-box">
                  <p>
                    LINE<span className="line-comment"></span>
                    アプリのインストールをお願いします。
                    <br />
                    LINE<span className="line-comment"></span>
                    アカウントを前機種から引き継いでいる場合には、特に再設定は必要ありませんが、LINEアカウントを新規発行した場合は、ユーザー設定が再度必要になります。
                    <br />
                    <br />
                    ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div
            className={
              'qa-lists function ' +
              (buttonSelect == 'function' ? 'active' : '')
            }
          >
            <ul>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    自動で配信が可能な災害を教えてください。
                  </p>
                  <button>機 能</button>
                </div>
                <div className="a-box">
                  <p>
                    メール・API連携・フォームからの手動配信など、システムで対応可能な通知手段であればどんな災害でも配信が可能です。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    対応できない通知内容はありますか。
                  </p>
                  <button>機 能</button>
                </div>
                <div className="a-box">
                  <p>
                    特殊な情報やデータで配信をしている場合を除き対応可能です。
                    <br />
                    内容に関しては一度ご相談ください。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    車両登録台数や呼称は分団により異なりますが対応できますか。
                  </p>
                  <button>機 能</button>
                </div>
                <div className="a-box">
                  <p>対応可能です。</p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    水利位置を座標データで保有していない場合でも対応できますか。
                  </p>
                  <button>機 能</button>
                </div>
                <div className="a-box">
                  <p>
                    水利マップの表示機能以外はお使いいただけます。
                    <br />
                    水利を住所や他社地図サービスで管理している場合には連携ができる場合があります。
                    <br />
                    また導入後でも水利情報システムに登録できますので、ご相談ください。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    水利位置が登録されてなくても、火点の通知はできますか。
                  </p>
                  <button>機 能</button>
                </div>
                <div className="a-box">
                  <p>
                    可能です。
                    <br />
                    その場合は、火点と火点からの距離円のみ画面に表示されます。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    自治体の様式に合わせて報告書の出力ができますか。
                  </p>
                  <button>機 能</button>
                </div>
                <div className="a-box">
                  <p>
                    Microsoft
                    Excelなどを使用している場合は基本的に対応可能です。
                    <br />
                    特殊なファイル形式や計算式、スクリプトが埋め込まれた場合には対応できない場合もありますので、一度ご相談ください
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div
            className={
              'qa-lists contract ' +
              (buttonSelect == 'contract' ? 'active' : '')
            }
          >
            <ul>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    システム利用の契約は消防団、自治体のどちらと締結するのか教えてほしい。
                  </p>
                  <button>契　約</button>
                </div>
                <div className="a-box">
                  <p>
                    どちらでも可能です。
                    <br />
                    分団や隊などで締結したい場合でも対応可能です。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">試しに使うことはできますか。</p>
                  <button>契　約</button>
                </div>
                <div className="a-box">
                  <p>
                    可能です。
                    <br />
                    お試し期間・人数につきましてはご相談ください。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    団員にシステム利用料が発生しますか。
                  </p>
                  <button>契　約</button>
                </div>
                <div className="a-box">
                  <p>
                    発生しません。
                    <br />
                    ただし、LINE<span className="line-comment"></span>
                    メッセージの送受信や地図表示にかかる通信費用はユーザー負担となります。
                    <br />
                    <br />
                    ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div
            className={
              'qa-lists system ' + (buttonSelect == 'system' ? 'active' : '')
            }
          >
            <ul>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    LINE<span className="line-comment"></span>
                    を使っていない団員の勤怠記録の登録方法を教えてほしい。
                  </p>
                  <button>システム</button>
                </div>
                <div className="a-box">
                  <p>
                    手動でシステムに出動記録を行うことができます。
                    <br />
                    <br />
                    ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    新たに機能を追加してほしいときに相談できますか。
                  </p>
                  <button>システム</button>
                </div>
                <div className="a-box">
                  <p>
                    可能です。
                    <br />
                    基本機能とするのか、自治体独自のオプション機能とするのか等はご相談となります。
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">資料請求はできますか。</p>
                  <button>システム</button>
                </div>
                <div className="a-box">
                  <p>
                    可能です。
                    <br />
                    お問い合わせフォームよりご連絡ください
                  </p>
                </div>
              </li>
              <li>
                <div className="q-box">
                  <p className="text-zen-maru">
                    LINE<span className="line-comment"></span>
                    で個人情報を取り扱っているが問題ありませんか。
                  </p>
                  <button>システム</button>
                </div>
                <div className="a-box">
                  <p>
                    問題ありません。
                    <br />
                    LINE社には個人情報は記録されておらず、当社契約のクラウドサーバーに直接データが通知されます。
                    <br />
                    当該クラウドサーバーにおいて、団員氏名などの個人情報を紐づけます。
                    <br />
                    これは「政府機関・地方公共団体等における業務での LINE
                    利用状況調査を踏まえた今後のLINE
                    サービス等の利用の際の考え方（ガイドライン）」　に準拠したシステム構成となっております。
                    <br />
                    <br />
                    ※「LINE」はLINEヤフー株式会社の商標または登録商標です。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </article>

        <article id="commutas-form">
          <div className="title-bg anime-up">
            <div className="title-flex sp-none">
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
              <h2 className="text-zen-maru">資料請求・お問い合わせ</h2>
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
            </div>
            <p className="title-supplement sp-none">contact</p>
            <h2 className="title-image-wrapper pc-none">
              <SvgImage
                filename="ttl_sp_contact"
                alt="資料請求・お問い合わせ"
              />
            </h2>
          </div>

          <h3 className="form-title text-zen-maru">
            「コミュたす」に関するどんなことでも、お気軽にお問い合わせください。
          </h3>
          <div className="form-text">
            <p>
              ■お見積りについて
              <br />
              ・アプリ導入に関する費用の他、システム連携のお見積りも承ります。
            </p>
            <p>
              ■デモの実施
              <br />
              ・アプリの画面をお見せしながら、実際の動作をご覧いただくことができます。
              <br />
              ・出火報の受信、鎮火時の報告から活動記録の取得まで、すべての機能のデモをいたします。
            </p>
          </div>

          <ContactForm />
        </article>

        <article id="commutas-vision">
          <div className="title-bg anime-up">
            <div className="title-flex sp-none">
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
              <h2 className="text-zen-maru">コミュたすのビジョン</h2>
              <p className="xxx-wrapper">
                <SvgImage filename="xxx" alt="×××" />
              </p>
            </div>
            <p className="title-supplement sp-none">vision</p>
            <h2 className="title-image-wrapper pc-none">
              <SvgImage filename="ttl_sp_vision" alt="コミュたすのビジョン" />
            </h2>
          </div>

          <div className="vision-text anime-up">
            <p className="vision-title">私たちが目指すところ</p>
            <p className="vision-sub">
              【屋外活動に携わるコミュニティに情報をプラス】
            </p>
            <p>
              屋外活動に携わるコミュニティとは、例えば林業、
              <br className="pc-none" />
              例えば防災、例えばPTA、
              <br className="sp-none" />
              例えば警察、工事、研究、
              <br className="pc-none" />
              自治体、イベント、etc...これらを指します。
              <br />
              プラスする情報とは、活動時間の記録、
              <br className="pc-none" />
              位置、周辺情報、到着確認、
              <br />
              それらのコミュニティ内共有となります。
            </p>

            <p className="vision-logo">~Vision~</p>
            <p className="vision-title2">社会を、ちょっとよくしたい。</p>
            <p className="vision-explain">
              我々のサービスによって、コミュニティの活動を
              <br className="pc-none" />
              サポートし、
              <br className="sp-none" />
              良質な既存のコミュニティの持続、
              <br className="pc-none" />
              新たなコミュニティの登場を
              <br />
              後押しすることで、社会をちょっとよくしたい。
            </p>
            <p className="vision-explain-sub">
              【社会をよくするとは】
              <br />
              コミュニティに所属する方の心の豊かさ、
              <br />
              コミュニティが存在することによる地域の安心安全を指します。
            </p>

            <div className="commutas-logo">
              <SvgImage filename="main-title" alt="コミュたす" />
            </div>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>【コミュたす】消防団の業務を効率化を実現するアプリ</title>
    <meta
      name="description"
      content="【コミュたす】は、消防団の業務を効率化するために、現役消防団員が地元消防関係者の協力を得てつくった消防団のためのアプリ。だから痒いところに手が届く機能が満載。まずはお気軽に資料請求ください。"
    />
    <meta name="keywords" content="コミュたす" />
    <meta property="og:url" content="https://www.commutas.jp" />
    <meta property="og:type" content="website" />
    <meta
      property="og:title"
      content="【コミュたす】消防団の業務を効率化を実現するアプリ"
    />
    <meta
      property="og:description"
      content="【コミュたす】は、消防団の業務を効率化するために、現役消防団員が地元消防関係者の協力を得てつくった消防団のためのアプリ。だから痒いところに手が届く機能が満載。まずはお気軽に資料請求ください。"
    />
    <meta property="og:site_name" content="コミュたす" />
    <meta property="og:locale" content="ja_JP" />
  </>
);
