import React from "react";
import PcImage from "./pc-image";
import SpImage from "./sp-image";

const NormalImage = ({alt, filename}) => {

  return (
    <>
      <PcImage filename={filename} alt={alt} className="sp-none"/>
      <SpImage filename={filename} alt={alt} className="pc-none"/>
    </>
  )
}

export default NormalImage