import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {useStaticQuery, graphql} from "gatsby";

const SpImage = ({alt, filename}) => {
  const {allFile} = useStaticQuery(
    graphql`
    query imageSPQuery{
      allFile(filter: {
        extension: {in: ["png", "jpg", "jpeg"]}, relativePath: {regex: "/^SP/.+?/"}}) {
          nodes {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])
            }
          }
        }
    }
  `)

  return <GatsbyImage image={getImage(allFile.nodes.find(n => n.name === filename))} alt={alt} className="pc-none" loading="eager" />
}




export default SpImage