import * as React from "react";
import { FC } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import scrollTo from "gatsby-plugin-smoothscroll";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const kanaRegExp = /^[ぁ-ん 　]+$/;

const ContactForm: FC = () => {
  const [formDisplay, setFormDisplay] = React.useState<string>("input");
  const [loading, setLoading] = React.useState<boolean>(false)

  return (
    <Formik
      initialValues={{
        request: "資料請求",
        name: "",
        kana: "",
        mail: "",
        tell: "",
        division: "",
        department: "",
        question: "",
        consent: false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("必須項目です"),
        kana: Yup.string()
          .required("必須項目です")
          .matches(kanaRegExp, "ひらがなで入力してください"),
        mail: Yup.string()
          .required("必須項目です")
          .email("メールアドレスの形式に誤りがあります"),
        tell: Yup.string()
          .required("必須項目です")
          .matches(phoneRegExp, "電話番号の形式に誤りがあります"),
        division: Yup.string().required("必須項目です"),
        department: Yup.string(),
        question: Yup.string().required("必須項目です"),
        consent: Yup.boolean().oneOf([true], "同意が必要です"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (formDisplay === "input") {
          // 入力ページのサブミットは表示切替のみ
          setFormDisplay("confirm");
          return;
        }

        setLoading(true)
        try {
          const response = await fetch(
            "https://contact.commutas.jp/contact",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            }
          );
          const body = response.json();
          if (!response.ok) {
            alert(
              "お問い合わせの送信に失敗しました。\r\n時間をおいてやり直してください。"
            );
          } else {
            setFormDisplay("complate");
            scrollTo("#form-body");
            if (typeof window !== "undefined") {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({ event: "formSubmit" });
            }
          }
        } catch (error) {
          // error
        } finally {
          setLoading(false)
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className={"form-body anime-up " + formDisplay} id="form-body">
            <div className="complate-message">
              <p className="message-title">お問い合わせが完了しました</p>
              <p className="message-body">
                お問い合わせいただきありがとうございました。
                <br />
                <br />
                折り返し、担当者よりご連絡いたしますので
                <br />
                恐れ入りますが、しばらくお待ちください。
                <br />
                <br />
                数日経ってもご連絡がない場合、
                <br />
                恐れ入りますが再度フォームよりご連絡ください。
              </p>
            </div>
            <div className="form-input form-input-request">
              <div className="label-wrapper">
                <div className="label">ご希望の項目</div>
                <div className="require-box require">必 須</div>
              </div>
              <div className="radio-box">
                <label htmlFor="request1">
                  <Field
                    type="radio"
                    className="radio-input"
                    id="request1"
                    name="request"
                    value="資料請求"
                    checked={formik.values.request === "資料請求"}
                    onChange={() => formik.setFieldValue("request", "資料請求")}
                  />
                  <span className="radio-text">資料請求</span>
                </label>
                <label htmlFor="request2">
                  <Field
                    type="radio"
                    className="radio-input"
                    id="request2"
                    name="request"
                    value="無料デモ"
                    checked={formik.values.request === "無料デモ"}
                    onChange={() => formik.setFieldValue("request", "無料デモ")}
                  />
                  <span className="radio-text">無料デモ</span>
                </label>
                <label htmlFor="request3">
                  <Field
                    type="radio"
                    className="radio-input"
                    id="request3"
                    name="request"
                    value="お問い合わせ/お見積もり"
                    checked={
                      formik.values.request === "お問い合わせ/お見積もり"
                    }
                    onChange={() =>
                      formik.setFieldValue("request", "お問い合わせ/お見積もり")
                    }
                  />
                  <span className="radio-text">お問い合わせ/お見積り</span>
                </label>
              </div>
            </div>
            <div className="form-input form-input-name">
              <div className="label-wrapper">
                <div className="label">お名前</div>
                <div className="require-box require">必 須</div>
              </div>
              <div>
                <Field name="name" placeholder="古未 助" type="text" />
                <div className="error-box">
                  <ErrorMessage name="name" />
                </div>
              </div>
            </div>
            <div className="form-input form-input-kana">
              <div className="label-wrapper">
                <div className="label">ふりがな</div>
                <div className="require-box require">必 須</div>
              </div>
              <div>
                <Field
                  type="text"
                  name="kana"
                  id="kana"
                  placeholder="こみゅ　たす"
                />
                <div className="error-box">
                  <ErrorMessage name="kana" />
                </div>
              </div>
            </div>
            <div className="form-input form-input-mail">
              <div className="label-wrapper">
                <div className="label">メールアドレス</div>
                <div className="require-box require">必 須</div>
              </div>
              <div>
                <Field
                  type="mail"
                  name="mail"
                  id="mail"
                  placeholder="example@commutas.jp"
                />
                <div className="error-box">
                  <ErrorMessage name="mail" />
                </div>
              </div>
            </div>
            <div className="form-input form-input-tell">
              <div className="label-wrapper">
                <div className="label">電話番号</div>
                <div className="require-box require">必 須</div>
              </div>
              <div>
                <Field
                  type="text"
                  name="tell"
                  id="tell"
                  placeholder="03-1234-5678"
                />
                <div className="error-box">
                  <ErrorMessage name="tell" />
                </div>
              </div>
            </div>
            <div className="form-input form-input-community">
              <div className="label-wrapper">
                <div className="label">自治体名・団体名</div>
                <div className="require-box require">必 須</div>
              </div>
              <div>
                <Field
                  type="text"
                  name="division"
                  id="division"
                  placeholder="コミュたす消防団"
                />
                <div className="error-box">
                  <ErrorMessage name="division" />
                </div>
              </div>
            </div>
            <div className="form-input form-input-department">
              <div className="label-wrapper">
                <div className="label">部署名</div>
                <div className="require-box"></div>
              </div>
              <div>
                <Field
                  type="text"
                  name="department"
                  id="department"
                  placeholder="コミュたす分団"
                />
                <div className="error-box">
                  <ErrorMessage name="department" />
                </div>
              </div>
            </div>
            <div className="form-input form-input-text">
              <div className="label-wrapper">
                <div className="label">お問い合わせ内容</div>
                <div className="require-box require">必 須</div>
              </div>
              <div>
                <Field
                  name="question"
                  id="question"
                  component="textarea"
                  placeholder="例）
導入を考えています。費用感をおしえてください。"
                />
                <div className="error-box">
                  <ErrorMessage name="question" />
                </div>
              </div>
            </div>
            <div className="form-input-check">
              <div className="check-wrapper">
                <label htmlFor="consent">
                  <Field
                    type="checkbox"
                    className="check-input"
                    id="consent"
                    name="consent"
                    value="true"
                    checked={formik.values.consent}
                    onChange={() =>
                      formik.setFieldValue("consent", !formik.values.consent)
                    }
                  />
                  <span className="check-text"></span>
                </label>
                <span>
                  <a
                    href="https://avasys.jp/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                  に同意する
                </span>
              </div>
              <div className="error-box">
                <ErrorMessage name="consent" />
              </div>
            </div>

            <div className="confirm-buttons">
              <button
                type="button"
                className="button-back text-zen-maru"
                onClick={() => {
                  setFormDisplay("input");
                  scrollTo("#form-body");
                }}
              >
                戻　る
              </button>
              <button type={loading ? 'button' : 'submit'} className="button-submit text-zen-maru">
                {loading ? <div className="loader" /> : <>送信する</>}
              </button>
            </div>
            <div className="submit-buttons">
              <button
                type="submit"
                className="button-confirm text-zen-maru"
                onClick={() => {
                  scrollTo("#form-body");
                }}
              >
                送信内容を確認する
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
