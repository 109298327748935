import React from 'react';
import SvgImage from '../../../components/image/svg-image';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-company">
        <a href="https://avasys.jp/" target="_blank" rel="noopener noreferrer">
          <SvgImage filename="avasys-logo" alt="アヴァシス" />
        </a>
        <p>運営会社：エプソンアヴァシス株式会社</p>
      </div>

      <div className="footer-info">
        <p className="privacy-policy">
          <a
            href="https://avasys.jp/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </a>
          <a href="/whitepaper.pdf" target="_blank" rel="noopener noreferrer">
            ホワイトペーパー
          </a>
        </p>
        <p className="copyright">
          Copyright &copy; 2024 EPSON AVASYS CORPORATION
        </p>
      </div>
    </footer>
  );
}

export default Footer;
